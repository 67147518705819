import React from 'react';
import './Advantages.scss';
import imgLeft from './assets/LiteCoin-3.png';
import imgRight from './assets/Bitcoin-1.png';
import img1 from './assets/img1.svg';
import img2 from './assets/img2.svg';
import img3 from './assets/img3.svg';
import img4 from './assets/img4.svg';
import img5 from './assets/img5.svg';
import img6 from './assets/img6.svg';
import SectionProps from '../../../models/SectionProps';

interface AdvantageItemProps {
  item: any,
}

const AdvantageItem: React.FC<AdvantageItemProps> = ({ item }) => {
  return (
    <li className="advantages__item">
      <img className="advantages__item-img" src={item.img} alt="BG img" />
      <h2 className="advantages__item-title">{item.title}</h2>
      <p className="advantages__item-text">{item.text}</p>
    </li>
  );
};

const Advantages: React.FC<SectionProps> = ({ lang }) => {
  const list: any[] = [
    {
      id: 0,
      title: lang.list[0].title,
      text: lang.list[0].text,
      img: img1,
    },
    {
      id: 1,
      title: lang.list[1].title,
      text: lang.list[1].text,
      img: img2,
    },
    {
      id: 2,
      title: lang.list[2].title,
      text: lang.list[2].text,
      img: img3,
    },
    {
      id: 3,
      title: lang.list[3].title,
      text: lang.list[3].text,
      img: img4,
    },
    {
      id: 4,
      title: lang.list[4].title,
      text: lang.list[4].text,
      img: img5,
    },
    {
      id: 5,
      title: lang.list[5].title,
      text: lang.list[5].text,
      img: img6,
    },
  ];

  return (
    <section className="advantages">
      <div className="advantages__id-link" id="advantages" />
      <img className="advantages__bg-img-left" src={imgLeft} alt="BG img" />
      <img className="advantages__bg-img-right" src={imgRight} alt="BG img" />
      <div className="wrapper advantages__wrapper">
        <h2 className="advantages__title">{lang.title}</h2>
        <p className="advantages__description">{lang.description}</p>
        <ul className="advantages__list">
          {
            list.length > 0 && list.map((advantage: any) => <AdvantageItem key={advantage.id} item={advantage} />)
          }
        </ul>
      </div>
    </section>
  );
};

export default Advantages;
