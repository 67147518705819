import React from 'react';
import './CustomToast.scss';
import { DefaultToast } from 'react-toast-notifications';


const CustomToast: React.FC = ({ children, ...props }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <DefaultToast {...props} className="custom-toast">
    {children}
  </DefaultToast>
);

export default CustomToast;
