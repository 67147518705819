import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './ui/routes/Routes';
import { ToastProvider } from 'react-toast-notifications';
import CustomToast from './ui/components/customToast/CustomToast';

function App(): any {
  return (
    <BrowserRouter>
      <ToastProvider
        autoDismiss
        components={{ Toast: CustomToast }}
      >
        <Routes />
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
