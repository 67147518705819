import React, { useState } from 'react';
import './Main.scss';
import MainLayout from '../../layouts/main/MainLayout';
import Contact from '../../components/contact/Contact';
import Faq from '../../components/faq/Faq';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import Exchange from '../../components/exchange/Exchange';
import Advantages from '../../components/advantages/Advantages';
import Steps from '../../components/steps/Steps';
import Cooperation from '../../components/cooperation/Cooperation';
import Calculator from '../../components/calculator/Calculator';
import Solutions from '../../components/Solutions/Solutions';
import Individual from '../../components/individual/Individual';
import fileLang from '../../../lang.json';

const Main: React.FC = () => {
  const [lang, setLang] = useState<'ru' | 'en'>('en');

  const changeLang = (langStr: 'ru' | 'en') => {
    setLang(langStr);
  };

  return (
    <MainLayout>
      <Header changeLang={changeLang} langStr={lang} lang={fileLang[lang].header} />
      <Banner lang={fileLang[lang].banner} />
      <Calculator lang={fileLang[lang].calc} />
      <Exchange lang={fileLang[lang].exchange} />
      <Solutions lang={fileLang[lang].solutions} />
      <Advantages lang={fileLang[lang].advantages} />

      <Cooperation lang={fileLang[lang].cooperation} />
      <Steps lang={fileLang[lang].steps} />
      <Individual lang={fileLang[lang].individual} />
      <Faq lang={fileLang[lang].faq} />
      <Contact lang={fileLang[lang].footer} />
    </MainLayout>
  );
};

export default Main;
