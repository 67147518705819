import React, { useEffect, useRef, useState } from 'react';
import './Calculator.scss';
import { HashLink as Link } from 'react-router-hash-link';
import Bitcoin from './assets/Bitcoin.svg';
import Swift from './assets/Swift.svg';
import Tether from './assets/Teter.svg';
import Cash from './assets/Cash.svg';
import Pound from './assets/Pound.svg';
import Euro from './assets/Euro.svg';
import Ruble from './assets/Ruble.svg';
import logo from './assets/logo.svg';
import copy from './assets/copy.svg';
import CustomSelect from '../customSelect/CustomSelect';
import classNames from 'classnames';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { isMatched, isRequired } from '../../../utilities/FormValidation';
import SectionProps from '../../../models/SectionProps';

const Calculator: React.FC<SectionProps> = ({ lang }) => {
  const [wrapperHeight, setWrapperHeight] = useState<number>(0);
  const wrapperRef = useRef(null);

  const { addToast } = useToasts();

  const [code, setCode] = useState<string>('000001');
  const [secondScreen, setSecondScreen] = useState<boolean>(false);

  const [list, setList] = useState([
    {
      id: 1,
      icon: Bitcoin,
      name: 'Bitcoin - BTC',
      code: 'BTC',
    },
    {
      id: 2,
      icon: Tether,
      name: 'Tether - USDT',
      code: 'USDT',
    },
    {
      id: 3,
      icon: Swift,
      name: 'SWIFT - USD',
      code: 'USD',
    },
    {
      id: 4,
      icon: Cash,
      name: 'Dollar - USD',
      code: 'USD',
    },
    {
      id: 5,
      icon: Euro,
      name: 'Euro - EUR',
      code: 'EUR',
    },
    {
      id: 6,
      icon: Pound,
      name: 'Pound - GBP',
      code: 'GBP',
    },
    {
      id: 7,
      icon: Ruble,
      name: 'Ruble - RUB',
      code: 'RUB',
    },
  ]);
  const [currentGive, setCurrentGive] = useState(list[0]);
  const [currentGet, setCurrentGet] = useState(list[3]);

  const [coefficient, setCoefficient] = useState<number>(1);

  const [valueGive, setValueGive] = useState<any>(1);
  const [valueGet, setValueGet] = useState<number>(1);

  const [listGiveIsOpen, setListGiveIsOpen] = useState<boolean>(false);
  const [listGetIsOpen, setListGetIsOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState<{ [key: string]:boolean }>({
    fName: false,
    email: false,
  });

  const closeListGive = (): void => setListGiveIsOpen(false);
  const closeListGet = (): void => setListGetIsOpen(false);

  const convert = (from: string, to: string) => {
    if (from === 'USDT' && to === 'USDT') {
      setCoefficient(1);
      setValueGet(valueGive);
    } else if (from === 'RUB' && to === 'RUB') {
      setCoefficient(1);
      setValueGet(valueGive);
    } else {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const requestOptions: {
        method: string;
        headers: Headers;
        redirect: 'follow' | 'error' | 'manual' | undefined;
      } = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      let toUsdt = false;
      if (to === 'USDT' || from === 'RUB') {
        toUsdt = true;
      }

      fetch(`https://api.exchangerate.host/convert?from=${toUsdt ? to : from}&to=${toUsdt ? from : to}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
        })
        .then(result => {
          if (result) {
            if (toUsdt) {
              setCoefficient(1 / result.result);
              setValueGet(valueGive * (1 / result.result));
            } else {
              setCoefficient(result.result);
              setValueGet(valueGive * result.result);
            }
          }
        })
        .catch(error => console.log('error', error));
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setWrapperHeight(wrapperRef.current ? wrapperRef.current.clientHeight : 280);

    convert(currentGive.code, currentGet.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickListGive = () => {
    setListGiveIsOpen(!listGiveIsOpen);
  };

  const handleClickListGet = () => {
    setListGetIsOpen(!listGetIsOpen);
  };

  const handleSelectGive = (id: number | string, item: any) => {
    setCurrentGive(item);
    convert(item.code, currentGet.code);
  };

  const handleSelectGet = (id: number | string, item: any) => {
    setCurrentGet(item);
    convert(currentGive.code, item.code);
  };
  
  const validate = (values: { [p: string]: FormDataEntryValue }) => {
    const validateResults: { [key: string]: boolean } = {
      fName: isRequired(values.fName, true),
      email: isMatched(values.email, /\S+@\S+\.\S+/),
    };

    setErrors(validateResults);

    let isError = false;
    Object.entries(validateResults).some((value) => {
      if (value[1]) {
        isError = true;
      }
    });

    if (!isError) {
      axios.post('https://api.xe-balance.pro', JSON.stringify(values))
        .then(result => {
          console.log(result);
          console.log(result.data);

          setCode(result.data.ID);
          setSecondScreen(true);

          addToast(lang.toast, {
            appearance: 'success',
            autoDismiss: true,
          });
        }).catch((error) => {
          console.log(error);
        });
    }
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const formData = new FormData(evt.target as HTMLFormElement);

    const fieldForm = Object.fromEntries(formData.entries());

    const nameStr = String(fieldForm.fName);
    fieldForm.fName = nameStr.split(' ', 1)[0];
    fieldForm.lName = nameStr.split(' ').slice(1).join();

    validate(fieldForm);
  };

  const onChangeValue = (evt: React.ChangeEvent<HTMLInputElement>, currentGiveId: number) => {
    let userString = evt.target.value;
    console.log(userString);
    userString = evt.target.value.replace(/[^0-9]/g, (function () {
      let dotCount = 0;
      return function ($0: string) {
        if ($0 === '.' && !dotCount) {
          dotCount += 1;
          return $0;
        }
        return '';
      };
    }()));

    console.log(userString);

    setValueGive(userString);
    // setValueGet(Math.round(+evt.target.value * coefficient * 100) / 100);
    setValueGet(+userString * coefficient);
  };

  function toPlainString(num: string | number) {
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join('0') + c + d
          : b + c + d + Array(e - d.length + 1).join('0');
      });
  }

  return (
    <section className="calculator">
      <div className="calculator__id-link" id="exchange" />
      <div className={classNames('wrapper', 'calculator__wrapper', secondScreen && 'calculator__wrapper--none')} ref={wrapperRef}>
        <form action="post" onSubmit={(evt) => onSubmit(evt)}>
          <div className="calculator__top">
            <div className="calculator__give">
              <p>{lang.paying}</p>
              <CustomSelect
                additionalClassName="calculator__select"
                closeList={closeListGive}
                loading={false}
                items={list}
                currentItem={currentGive}
                isOpen={listGiveIsOpen}
                handleClick={handleClickListGive}
                handleSelect={handleSelectGive}
              />
              <input type="hidden" name="coinPost" value={currentGive.name} />
              {/*<input className={classNames('input', 'calculator__input-user', errors.fName ? 'input--error' : '')} type="text" name="coinPostValues" required value={valueGive} onChange={(evt) => onChangeValue(evt, currentGive.id)} />*/}
            </div>

            <div className="calculator__logo">
              <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="animate-circle" fill="url(#paint0_linear_525_865)" d="M0 40.9119C0.191282 29.5837 4.1657 19.807 12.2421 11.7944C18.9582 5.12082 27.1196 1.25267 36.5349 0.253748C47.8631 -0.936452 58.0648 2.0603 66.9275 9.24401C74.6426 15.4926 79.3821 23.6114 81.2737 33.3456C82.2939 38.574 82.2089 43.8023 81.2099 49.0307C81.0187 50.0934 80.6361 51.0286 79.9347 51.8574C78.9571 52.9839 77.7243 53.4302 76.2791 53.0902C74.8764 52.7501 74.0262 51.8149 73.6012 50.4335C73.4311 49.8171 73.4736 49.2433 73.6012 48.6482C75.8965 37.8301 73.6012 28.0747 66.5025 19.5945C60.8277 12.8146 53.474 8.94646 44.6963 8.05381C34.5371 6.99113 25.6318 10.0304 18.1931 17.0228C12.2633 22.5913 8.884 29.4987 8.03385 37.575C7.05619 47.0541 9.67038 55.5343 15.8552 62.803C21.5511 69.4767 28.8411 73.3448 37.5763 74.2375C47.8631 75.3001 56.7896 72.1546 64.3133 65.0559C64.9722 64.4396 65.6098 63.8657 66.4174 63.4832C68.9466 62.2717 71.7733 64.142 71.6246 66.9262C71.5821 67.8401 71.242 68.6478 70.5831 69.3279C64.3558 75.7465 56.8321 79.7421 48.0543 81.3362C44.6538 81.9525 41.2107 82.1013 37.7676 81.825C28.4373 81.0386 20.2759 77.5318 13.3685 71.2195C6.97117 65.396 2.86923 58.2123 0.977665 49.7958C0.255043 46.6928 0 44.0574 0 40.9119Z" />
                <path d="M35.9185 28.5851C35.9185 34.7911 35.9185 40.9972 35.9185 47.182C35.9185 49.3073 36.7686 51.0714 38.4051 52.4316C39.3828 53.2393 40.5305 53.7706 41.8482 53.8981C43.336 54.0256 44.7174 53.7919 46.0139 53.0267C46.8428 52.5166 47.6079 51.879 48.118 51.0501C48.7131 50.115 49.1382 49.0736 49.2445 47.9259C49.3295 46.8844 49.2232 45.8855 48.8406 44.9504C48.2881 43.6114 47.4379 42.5062 46.2052 41.7198C44.93 40.8909 43.8886 39.8495 43.0597 38.5743C42.1458 37.1715 41.6782 35.62 41.5082 33.9835C41.4657 33.6222 41.4444 33.2609 41.4869 32.8996C41.5932 32.0494 42.2733 31.7519 42.9321 31.7306C43.4635 31.7306 44.0161 31.7731 44.5474 31.8582C45.5463 32.0494 46.5453 32.2407 47.5017 32.5383C49.0107 33.0058 50.4134 33.7285 51.7099 34.6849C52.9213 35.5775 54.0053 36.5764 54.8979 37.7666C55.493 38.553 56.0669 39.3607 56.4919 40.2533C57.0658 41.5073 57.5971 42.7825 57.8309 44.164C58.0434 45.3967 58.2347 46.6081 58.1497 47.8408C58.0434 49.6899 57.7034 51.4752 57.002 53.218C56.4919 54.4932 55.8118 55.6834 54.9829 56.7886C54.3453 57.6387 53.6014 58.4039 52.7938 59.1052C51.8799 59.9129 50.9022 60.593 49.8396 61.1456C48.6706 61.7619 47.4379 62.2295 46.1414 62.5058C44.6962 62.8246 43.2297 62.9521 41.742 62.8671C39.6591 62.7396 37.6613 62.2508 35.7697 61.3156C34.707 60.7843 33.7081 60.1679 32.8154 59.4241C31.7103 58.5101 30.7538 57.49 29.925 56.2998C29.1173 55.1521 28.501 53.9406 28.0121 52.6442C27.6083 51.539 27.3533 50.3913 27.247 49.2223C27.1832 48.4997 27.0982 47.7558 27.077 47.0332C27.0557 46.2681 27.1195 45.503 27.1195 44.7378C27.1195 42.995 27.1195 41.2522 27.1195 39.5094C27.1195 38.1067 27.1195 36.7252 27.1195 35.3225C27.0982 30.4342 27.077 25.5671 27.0982 20.6788C27.0982 19.6161 27.5871 18.9785 28.8835 19.0847C30.3075 19.191 31.6252 19.6586 32.7942 20.5087C34.452 21.7202 35.4934 23.3142 35.8122 25.312C35.9397 26.396 35.961 28.5638 35.9185 28.5851Z" fill="url(#paint1_linear_525_865)"/>
                <defs>
                  <linearGradient id="paint0_linear_525_865" x1="41" y1="0" x2="41" y2="81.9583" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F0B90B"/>
                    <stop offset="1" stopColor="#F0A20B"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_525_865" x1="42.6215" y1="19.0732" x2="42.6215" y2="62.8928" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F0B90B"/>
                    <stop offset="1" stopColor="#F0A20B"/>
                  </linearGradient>
                </defs>
              </svg>
              <p>{lang.make}</p>
            </div>

            <div className="calculator__get">
              <p>{lang.receiving}</p>
              <CustomSelect
                additionalClassName="calculator__select"
                closeList={closeListGet}
                loading={false}
                items={list}
                currentItem={currentGet}
                isOpen={listGetIsOpen}
                handleClick={handleClickListGet}
                handleSelect={handleSelectGet}
              />
              <input type="hidden" name="coinGet" value={currentGet.name} />
              <input className="input calculator__input-user" name="coinGetValues" type="hidden" value={toPlainString(valueGet)} readOnly />
            </div>
          </div>
          <input className={classNames('input', 'calculator__input-user', errors.fName ? 'input--error' : '')} type="text" name="coinPostValues" required value={valueGive} onChange={(evt) => onChangeValue(evt, currentGive.id)} />
          <div className="calculator__bottom">
            <input className={classNames('input', errors.fName ? 'input--error' : '')} name="fName" type="text" placeholder={String(lang.name)} />
            <input className={classNames('input', errors.email ? 'input--error' : '')} name="email" type="text" placeholder={String(lang.email)} />
            <input className="input" name="telegram" type="text" placeholder="Telegram" />
            <button type="submit" className="btn-main">
              {lang.button}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#0C0E11"/>
                <path d="M14.5 21C14.3684 21.0008 14.2379 20.9755 14.1161 20.9258C13.9943 20.876 13.8834 20.8027 13.79 20.71C13.6963 20.617 13.6219 20.5064 13.5711 20.3846C13.5203 20.2627 13.4942 20.132 13.4942 20C13.4942 19.868 13.5203 19.7373 13.5711 19.6154C13.6219 19.4936 13.6963 19.383 13.79 19.29L17.1 16L13.92 12.69C13.7337 12.5026 13.6292 12.2492 13.6292 11.985C13.6292 11.7208 13.7337 11.4674 13.92 11.28C14.013 11.1863 14.1236 11.1119 14.2454 11.0611C14.3673 11.0103 14.498 10.9842 14.63 10.9842C14.762 10.9842 14.8927 11.0103 15.0146 11.0611C15.1364 11.1119 15.247 11.1863 15.34 11.28L19.2 15.28C19.3832 15.4669 19.4859 15.7182 19.4859 15.98C19.4859 16.2418 19.3832 16.4931 19.2 16.68L15.2 20.68C15.1102 20.7769 15.0022 20.8551 14.882 20.91C14.7619 20.965 14.632 20.9955 14.5 21Z" fill="#F6D658"/>
              </svg>
            </button>
          </div>
        </form>
      </div>
      <div className={classNames('wrapper', 'calculator__wrapper-second', secondScreen && 'calculator__wrapper-second--show')} style={{ minHeight: window.innerWidth > 580 ? wrapperHeight : 'auto' }}>
        <img className="calculator__logo-img" src={logo} alt="Logo" />
        <div className="input calculator__div-input">
          <p>{lang.request.number} <span>{code}</span></p>
          <button className="calculator__copy-btn" type="button" onClick={() => {
            navigator.clipboard.writeText(code);
            addToast(lang.request.toast, {
              appearance: 'success',
              autoDismiss: true,
            });
          }}
          >
            <img src={copy} alt={lang.request.img} />
          </button>
        </div>
        <p>{lang.request.text[0]} {lang.request.text[1]}<br />
          {lang.request.text[2]} <a href="https://t.me/BalanceSupport" target="_blank" rel="noreferrer">@BalanceSupport</a><br />
          {lang.request.text[3]}</p>
      </div>
    </section>
  );
};

export default Calculator;
