import React, { useEffect } from 'react';
import './Faq.scss';
import Collapsible from 'react-collapsible';
import imgLeft from './assets/Bitcoin-1.png';
import imgRight from './assets/Monero-1.png';
import SectionProps from '../../../models/SectionProps';

const Faq: React.FC<SectionProps> = ({ lang }) => (
  <section className="faq">
    {/* <div className="faq__id-link" id="faq" /> */}
    <img className="faq__bg-img-left" src={imgLeft} alt="BG img" />
    <img className="faq__bg-img-right" src={imgRight} alt="BG img" />
    <div className="wrapper faq__wrapper">
      <h2 className="title faq__title">{lang.title}</h2>
      <p className="faq__description">{lang.description}</p>
      <div className="faq__list">
        <Collapsible trigger={lang.list[0].title} className="Collapsible__first">
          <p dangerouslySetInnerHTML={{ __html: lang.list[0].text }} />
        </Collapsible>
        <Collapsible trigger={lang.list[1].title}>
          <p dangerouslySetInnerHTML={{ __html: lang.list[1].text }} />
        </Collapsible>
        <Collapsible trigger={lang.list[2].title}>
          <p dangerouslySetInnerHTML={{ __html: lang.list[2].text }} />
        </Collapsible>
        <Collapsible trigger={lang.list[3].title}>
          <p dangerouslySetInnerHTML={{ __html: lang.list[3].text }} />
        </Collapsible>
        <Collapsible trigger={lang.list[4].title}>
          <p dangerouslySetInnerHTML={{ __html: lang.list[4].text }} />
        </Collapsible>
        <Collapsible trigger={lang.list[5].title}>
          <p dangerouslySetInnerHTML={{ __html: lang.list[5].text }} />
        </Collapsible>
      </div>
    </div>
  </section>
);

export default Faq;
