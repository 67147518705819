import React from 'react';
import './Solutions.scss';
import fon from './assets/fon.png';
import img from './assets/img.svg';
import SectionProps from '../../../models/SectionProps';

const Solutions: React.FC<SectionProps> = ({ lang }) => {
  return (
    <section className="solutions">
      <div className="solutions__id-link" />
      <img src={fon} className="solutions__fon" alt="fon" />
      <div className="wrapper solutions__wrapper">
        <div className="solutions__block">
          <div className="solutions__block-text">
            <h2 className="solutions__block-title">{lang.title}</h2>
            <p className="solutions__block-description">{lang.description}</p>
          </div>
          <img className="solutions__block-img" src={img} alt="img" />
        </div>
      </div>
    </section>
  );
};

export default Solutions;
