import React from 'react';
import './Exchange.scss';
import { HashLink as Link } from 'react-router-hash-link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import imgLeft from './assets/Monero.png';
import imgRight from './assets/Etherium.png';

import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import img5 from './assets/img5.png';
import img6 from './assets/img6.png';
import img7 from './assets/img7.png';
import SectionProps from '../../../models/SectionProps';

interface ExchangeItemProps extends SectionProps {
  item: any,
}

const ExchangeItem: React.FC<ExchangeItemProps> = ({
  item, lang,
}) => (
  <li className="exchange__item">
    <a className="exchange__item-a">
      <div className="exchange__item-img-wrapper">
        <img className="exchange__item-img" src={item.img} alt="img" />
      </div>
      <div className="exchange__item-info">
        <h3 className="exchange__item-title">{item.title}</h3>
        <p className="exchange__item-p">{item.text}</p>
        <Link className="exchange__item-button" smooth to="/#exchange">{lang.button}</Link>
      </div>
    </a>
  </li>
);

const Exchange: React.FC<SectionProps> = ({ lang }) => {
  const list: any[] = [
    {
      id: 0,
      title: lang.list[0].title,
      text: lang.list[0].text,
      img: img1,
    },
    {
      id: 1,
      title: lang.list[1].title,
      text: lang.list[1].text,
      img: img2,
    },
    {
      id: 2,
      title: lang.list[2].title,
      text: lang.list[2].text,
      img: img3,
    },
    {
      id: 3,
      title: lang.list[3].title,
      text: lang.list[3].text,
      img: img4,
    },
    {
      id: 4,
      title: lang.list[4].title,
      text: lang.list[4].text,
      img: img5,
    },
    {
      id: 5,
      title: lang.list[5].title,
      text: lang.list[5].text,
      img: img6,
    },
    {
      id: 6,
      title: lang.list[6].title,
      text: lang.list[6].text,
      img: img7,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    className: 'exchange__list',
    slide: 'ul',
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <section className="exchange">
      <div className="exchange__id-link" id="services" />
      <img className="exchange__bg-img-left" src={imgLeft} alt="BG img" />
      <img className="exchange__bg-img-right" src={imgRight} alt="BG img" />
      <div className="wrapper exchange__wrapper">
        <h2 className="exchange__title">{lang.title} <span>Balance</span></h2>
        <p className="exchange__description">{lang.description}</p>
        <Slider {...settings}>
          {
            list.length > 0 && list.map((exchange: any) => <ExchangeItem lang={lang} key={exchange.id} item={exchange} />)
          }
        </Slider>
      </div>
    </section>
  );
};

export default Exchange;
