import React, { useState } from 'react';
import './Cooperation.scss';
import fon from './assets/fon.png';
import imgLeft from './assets/img1.png';
import imgRight from './assets/img2.png';
import { isMatched } from '../../../utilities/FormValidation';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import SectionProps from '../../../models/SectionProps';

const Cooperation: React.FC<SectionProps> = ({ lang }) => {
  const { addToast } = useToasts();

  const [success, setSuccess] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]:boolean }>({
    email: false,
  });

  const sendRequest = (values: { [p: string]: FormDataEntryValue }, storage: string[]) => {
    axios.post('https://api.xe-balance.pro', JSON.stringify(values))
      .then(result => {
        console.log(result);
        console.log(result.data);

        setSuccess(true);

        addToast(lang.success, {
          appearance: 'success',
          autoDismiss: true,
        });

        sessionStorage.setItem('userEmails', JSON.stringify(storage));
      }).catch((error) => {
        console.log(error);
      });
  };

  const validate = (values: { [p: string]: FormDataEntryValue }) => {
    const validateResults: { [key: string]: boolean } = {
      email: isMatched(values.email, /\S+@\S+\.\S+/),
    };

    setErrors(validateResults);

    let isError = false;
    Object.entries(validateResults).some((value) => {
      if (value[1]) {
        isError = true;
      }
    });

    if (!isError) {
      const storage = sessionStorage.getItem('userEmails');

      if (storage) {
        const newStorage = JSON.parse(storage);
        const isDublicate = newStorage.find((value: string) => value === values.email);

        if (isDublicate) {
          setSuccess(true);
          addToast(lang.successAgain, {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          newStorage.push(values.email);
          sessionStorage.setItem('userEmails', JSON.stringify(newStorage));

          sendRequest(values, newStorage);
        }
      } else {
        sendRequest(values, [String(values.email)]);
      }
    }
  };

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setSuccess(false);

    const formData = new FormData(evt.target as HTMLFormElement);
    const fieldForm = Object.fromEntries(formData.entries());

    validate(fieldForm);
  };
  
  return (
    <section className="cooperation">
      <div className="cooperation__id-link" id="vip" />
      <img src={fon} className="cooperation__fon" alt="fon" />
      <div className="wrapper cooperation__wrapper">
        <form onSubmit={onSubmit}>
          <article className="cooperation__block">
            <img className="cooperation__block-img-left" src={imgLeft} alt="BG img" />
            <img className="cooperation__block-img-right" src={imgRight} alt="BG img" />
            <div className="cooperation__block-text">
              <div>
                <h2 className="cooperation__block-title">{lang.title}</h2>
                <p className="cooperation__block-description">{lang.description}</p>
              </div>
              <div className="cooperation__wrapper-input">
                <input className="cooperation__block-input" type="text" name="email" placeholder={lang.email} />
                {
                  errors.email && <p className="cooperation__form-result">{lang.error}</p>
                }
                {
                  success && <p className="cooperation__form-result">{lang.success}</p>
                }
              </div>
              <input type="hidden" name="subscribe" value="subscribe" />
              <button className="cooperation__block-button" type="submit">{lang.button}</button>
            </div>
          </article>
        </form>
      </div>
    </section>
  );
};

export default Cooperation;
