import React from 'react';
import './MainLayout.scss';

const MainLayout: React.FC = ({ children }) => (
  <div className="main-layout">
    <main className="main-layout__main">
      {children}
    </main>
  </div>
);

export default MainLayout;
