import React from 'react';
import './Individual.scss';
import fon from './assets/fon.png';
import img from './assets/img.svg';
import SectionProps from '../../../models/SectionProps';

const Individual: React.FC<SectionProps> = ({ lang }) => {
  return (
    <section className="individual">
      <div className="individual__id-link" />
      <img src={fon} className="individual__fon" alt="fon" />
      <div className="wrapper individual__wrapper">
        <div className="individual__block">
          <svg className="individual__block-img" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="animate-circle" d="M0 15.9656C0.0746467 11.5449 1.62564 7.72958 4.77739 4.60271C7.39832 1.99837 10.5832 0.488846 14.2575 0.0990237C18.6783 -0.365445 22.6594 0.804021 26.1181 3.60742C29.1288 6.04588 30.9784 9.21422 31.7166 13.0129C32.1147 15.0533 32.0815 17.0936 31.6917 19.1339C31.617 19.5486 31.4677 19.9136 31.194 20.2371C30.8125 20.6766 30.3315 20.8508 29.7675 20.7181C29.22 20.5854 28.8883 20.2205 28.7224 19.6813C28.6561 19.4408 28.6726 19.2169 28.7224 18.9846C29.6182 14.763 28.7224 10.956 25.9522 7.64664C23.7377 5.00083 20.8679 3.4913 17.4425 3.14295C13.4779 2.72825 10.0027 3.9143 7.09973 6.64305C4.78568 8.8161 3.46693 11.5117 3.13516 14.6634C2.75364 18.3626 3.77381 21.6719 6.18738 24.5085C8.4102 27.1128 11.2551 28.6224 14.6639 28.9707C18.6783 29.3854 22.1618 28.1579 25.0979 25.3877C25.355 25.1472 25.6038 24.9232 25.919 24.7739C26.906 24.3012 28.0091 25.031 27.9511 26.1176C27.9345 26.4742 27.8018 26.7894 27.5446 27.0548C25.1145 29.5596 22.1784 31.1189 18.7529 31.7409C17.4259 31.9815 16.0822 32.0395 14.7386 31.9317C11.0975 31.6248 7.91255 30.2563 5.21698 27.793C2.72046 25.5204 1.1197 22.717 0.381528 19.4325C0.099529 18.2216 0 17.1931 0 15.9656Z" fill="url(#paint0_linear_522_484)"/>
            <path d="M14.017 11.1548C14.017 13.5767 14.017 15.9986 14.017 18.4121C14.017 19.2415 14.3487 19.93 14.9874 20.4608C15.3689 20.7759 15.8168 20.9833 16.331 21.0331C16.9116 21.0828 17.4507 20.9916 17.9567 20.693C18.2801 20.494 18.5787 20.2451 18.7778 19.9217C19.01 19.5567 19.1759 19.1503 19.2174 18.7024C19.2505 18.296 19.2091 17.9062 19.0598 17.5413C18.8441 17.0187 18.5124 16.5874 18.0313 16.2806C17.5337 15.9571 17.1273 15.5507 16.8038 15.053C16.4471 14.5056 16.2647 13.9002 16.1983 13.2615C16.1817 13.1205 16.1734 12.9795 16.19 12.8385C16.2315 12.5067 16.4969 12.3906 16.754 12.3823C16.9614 12.3823 17.177 12.3989 17.3844 12.4321C17.7742 12.5068 18.164 12.5814 18.5372 12.6975C19.1261 12.88 19.6735 13.162 20.1795 13.5352C20.6522 13.8836 21.0752 14.2734 21.4236 14.7379C21.6558 15.0447 21.8798 15.3599 22.0456 15.7083C22.2696 16.1976 22.4769 16.6953 22.5682 17.2344C22.6511 17.7154 22.7258 18.1882 22.6926 18.6693C22.6511 19.3908 22.5184 20.0875 22.2447 20.7677C22.0456 21.2653 21.7802 21.7298 21.4568 22.1611C21.2079 22.4928 20.9176 22.7914 20.6025 23.0651C20.2458 23.3803 19.8643 23.6457 19.4496 23.8613C18.9934 24.1019 18.5124 24.2843 18.0064 24.3922C17.4424 24.5166 16.8701 24.5663 16.2895 24.5332C15.4767 24.4834 14.6971 24.2926 13.9589 23.9277C13.5442 23.7203 13.1544 23.4798 12.806 23.1895C12.3747 22.8329 12.0015 22.4348 11.678 21.9703C11.3629 21.5224 11.1223 21.0497 10.9316 20.5437C10.774 20.1124 10.6745 19.6645 10.633 19.2084C10.6081 18.9264 10.5749 18.6361 10.5666 18.3541C10.5583 18.0555 10.5832 17.7569 10.5832 17.4583C10.5832 16.7782 10.5832 16.0981 10.5832 15.418C10.5832 14.8706 10.5832 14.3314 10.5832 13.784C10.5749 11.8764 10.5666 9.97706 10.5749 8.06942C10.5749 7.65471 10.7657 7.40589 11.2716 7.44736C11.8273 7.48883 12.3416 7.6713 12.7977 8.00306C13.4447 8.47583 13.8511 9.09788 13.9755 9.87753C14.0253 10.3005 14.0336 11.1465 14.017 11.1548Z" fill="url(#paint1_linear_522_484)"/>
            <defs>
              <linearGradient id="paint0_linear_522_484" x1="16" y1="0" x2="16" y2="31.9837" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F0B90B"/>
                <stop offset="1" stopColor="#F0A20B"/>
              </linearGradient>
              <linearGradient id="paint1_linear_522_484" x1="16.6328" y1="7.44287" x2="16.6328" y2="24.5432" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F0B90B"/>
                <stop offset="1" stopColor="#F0A20B"/>
              </linearGradient>
            </defs>
          </svg>
          <div className="individual__block-text">
            <h2 className="individual__block-title" dangerouslySetInnerHTML={{ __html: lang.title }} />
            <p className="individual__block-description">{lang.description}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Individual;
