import React, { Suspense } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import Main from './main/Main';

const Routes: React.FC = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <Route path="/" element={<Main />} />
    </Switch>
  </Suspense>
);

export default Routes;
