import React from 'react';
import './CustomSelect.scss';
import classNames from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';

import Airport from './assets/Airport.svg';
import Port from './assets/Port.svg';

interface CustomSelectProps {
  // ref?: any,
  closeList: () => void;
  loading: boolean,
  items: any,
  currentItem: any,
  isOpen: boolean,
  handleClick: () => void,
  handleSelect: (id: number | string, item?: any) => void,
  additionalClassName?: string,
  height?: number,
  ports?: boolean,
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  // ref,
  closeList,
  loading,
  items,
  currentItem,
  isOpen,
  handleClick,
  handleSelect,
  additionalClassName,
  height,
  ports = false,
}) => {
  const ref = useOnclickOutside(() => closeList());
  // console.log('CustomSelect currentItem', currentItem);

  return (
    <div className={classNames('select__wrapper', additionalClassName)} style={{ height }} ref={ref}>
      <div
        role="button"
        tabIndex={0}
        className={isOpen ? classNames('select', 'opened') : classNames('select')}
        onClick={handleClick}
        onKeyUp={handleClick}
      >
        <div className="select__selected-item" style={{ height }}>
          {loading && <span>Loading...</span>}
          {!loading && currentItem && (
            <>
              <img src={currentItem.icon} alt="" />
              <span>{currentItem.name}</span>
            </>
          )}
          <div className="select__selected-arrow" />
        </div>

        {!loading && items && isOpen && (
          <ul
            className="select__list"
            role="listbox"
            tabIndex={0}
            aria-labelledby="listboxSelect"
            aria-activedescendant={String(2)}
          >
            {items.map((item: any) => (
              <li
                key={item.id || item.product_type_id}
                id={String(item.id || item.product_type_id)}
                role="option"
                aria-selected="true"
                // aria-labelledby={String(item.id)}
                tabIndex={0}
                onClick={() => handleSelect(item.id || item.product_type_id || (item.id === 0 || item.product_type_id === 0 ? 0 : undefined), item)}
                onKeyUp={() => handleSelect(item.id || item.product_type_id || (item.id === 0 || item.product_type_id === 0 ? 0 : undefined), item)}
                style={{ height: height && height - 10 }}
              >
                <img src={item.icon} alt="" />
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
