import React from 'react';
import './Steps.scss';
import { HashLink as Link } from 'react-router-hash-link';
import img1 from './assets/img1.png';
import img2 from './assets/img2.png';
import img3 from './assets/img3.png';
import img4 from './assets/img4.png';
import imgRight from './assets/Etherium-2.png';
import SectionProps from '../../../models/SectionProps';

const Steps: React.FC<SectionProps> = ({ lang }) => {
  return (
    <section className="steps">
      <img className="steps__bg-img-right" src={imgRight} alt="BG img" />
      <div className="wrapper steps__wrapper">
        <h2 className="steps__block-title">{lang.title}</h2>
        <ul className="steps__list">
          <li className="steps__item">
            <img className="steps__item-img" src={img1} alt="BG img" />
            <h2 className="steps__item-title">{lang.list[0].title}</h2>
            <p className="steps__item-text">{lang.list[0].text}</p>
            <Link className="steps__item-button" smooth to="/#exchange">
              {lang.list[0].button}
            </Link>
          </li>
          <li className="steps__item">
            <img className="steps__item-img" src={img2} alt="BG img" />
            <h2 className="steps__item-title">{lang.list[1].title}</h2>
            <p className="steps__item-text">{lang.list[1].text}</p>
            <Link className="steps__item-button" smooth to="/#exchange">
              {lang.list[1].button}
            </Link>
          </li>
          <li className="steps__item">
            <img className="steps__item-img" src={img3} alt="BG img" />
            <h2 className="steps__item-title">{lang.list[2].title}</h2>
            <p className="steps__item-text">{lang.list[2].text}</p>
            <Link className="steps__item-button" smooth to="/#exchange">
              {lang.list[2].button}
            </Link>
          </li>
          <li className="steps__item">
            <img className="steps__item-img" src={img4} alt="BG img" />
            <h2 className="steps__item-title">{lang.list[3].title}</h2>
            <p className="steps__item-text">{lang.list[3].text}</p>
            <Link className="steps__item-button" smooth to="/#exchange">
              {lang.list[3].button}
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Steps;
